<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>抽奖统计</template>
    </breadcrumb-nav>
    <el-card>
      <el-tabs  v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="大转盘" name="turnTable">
          <stat-turn-table v-if="tabRefresh.turnTable"></stat-turn-table>
        </el-tab-pane>
        <el-tab-pane label="拆红包" name="red">
          <stat-red v-if="tabRefresh.red"></stat-red>
        </el-tab-pane>
        <el-tab-pane label="夺宝" name="hunt">
          <stat-hunt v-if="tabRefresh.hunt"></stat-hunt>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import StatTurnTable from './childComponents/StatTurnTable'
import StatRed from './childComponents/StatRed'
import StatHunt from './childComponents/StatHunt'
import BreadcrumbNav from '../common/BreadcrumbNav'
export default {
  name: 'StatLottery',
  components: {
    BreadcrumbNav,
    StatHunt,
    StatRed,
    StatTurnTable
  },
  data () {
    return {
      activeName: 'turnTable',
      tabRefresh: {
        turnTable: true,
        red: false,
        hunt: false
      }
    }
  },
  methods: {
    handleTabClick (tab) {
      this.activeName = tab.name
      this.switchTab(this.activeName)
    },
    switchTab (tab) {
      for (const key in this.tabRefresh) {
        if (key === tab) {
          this.tabRefresh[key] = true
        } else {
          this.tabRefresh[key] = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
