<template>
  <div>
    <div class="overview-layout">
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="out-border">
          <div class="layout-title">今日概况</div>
          <div class="layout-content" style="padding: 20px">
            <el-row>
              <el-col :span="6" class="color-danger overview-item-value">{{statPlay.playCost}}</el-col>
              <el-col :span="6" class="color-danger overview-item-value">{{statPlay.playerCount}}</el-col>
              <el-col :span="6" class="color-danger overview-item-value">{{statPlay.playTotal}}</el-col>
              <el-col :span="6" class="color-danger overview-item-value">{{statHit.rmbCount}}</el-col>
            </el-row>
            <el-row class="font-medium">
              <el-col :span="6" class="overview-item-title">消耗金币</el-col>
              <el-col :span="6" class="overview-item-title">兑码人数</el-col>
              <el-col :span="6" class="overview-item-title">兑码次数</el-col>
              <el-col :span="6" class="overview-item-title">开奖次数</el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
    <div class="overview-layout" style="margin-top: 20px">
    <el-row :gutter="24">
      <el-col :span="12">
        <div class="out-border">
          <div class="layout-title">消耗金币</div>
          <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
            <el-form-item label="日期范围" prop="date">
              <el-date-picker
                v-model="listQuery.date"
                type="daterange"
                align="right"
                prop="date"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="resetForm('listQuery')">重置</el-button>
            </el-form-item>
          </el-form>
          <div id="coin-chart" style="width: 95%;height: 350px;"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="out-border">
          <div class="layout-title">兑码次数</div>
          <el-form :model="userQuery" ref="userQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
            <el-form-item label="日期范围" prop="date">
              <el-date-picker
                v-model="userQuery.date"
                type="daterange"
                align="right"
                prop="date"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="userPickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitUser">查询</el-button>
              <el-button @click="resetForm('userQuery')">重置</el-button>
            </el-form-item>
          </el-form>
          <div id="user-chart" style="width: 95%;height: 350px;"></div>
        </div>
      </el-col>
    </el-row>
  </div>
  </div>
</template>

<script>
import { GetStatTodayRequest, listStatLotteryHistory, listStatLotteryCount } from '../../../network/task'
import * as echarts from 'echarts'

export default {
  name: 'StatHunt',
  data () {
    return {
      statHit: {
        rmbTotal: 0,
        rmbCount: 0,
        coinTotal: 0,
        coinCount: 0
      },
      statPlay: {
        playCost: 0,
        playerCount: 0,
        playTotal: 0
      },
      coinChart: '',
      userChart: '',
      coinCostList: [],
      countList: [],
      // 查询参数
      listQuery: {
        date: ''
      },
      userQuery: {
        date: ''
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      userPickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      options: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          show: false
        },
        series: [{
          name: '消耗金币',
          type: 'line',
          smooth: true
        }]
      },
      userOptions: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          show: false
        },
        series: [{
          name: '对码次数',
          type: 'line',
          smooth: true
        }]
      }
    }
  },
  mounted () {
    this.coinChart = echarts.init(document.getElementById('coin-chart'))
    this.userChart = echarts.init(document.getElementById('user-chart'))
    this.getStatToday()
    this.getCostCoin()
    this.getUserCount()
  },
  methods: {
    getStatToday () {
      GetStatTodayRequest({ rewardSource: [21] }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        this.statHit = result.data.statHit
        this.statPlay = result.data.statPlay
      })
    },
    getCostCoin () {
      const query = {
        rewardSource: 21,
        rewardType: 1
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      listStatLotteryHistory(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.coinCostList = result.data.list
        this.setCoinChartData()
      })
    },
    setCoinChartData () {
      this.options.xAxis[0].data = []
      this.options.series[0].data = []
      for (let i = 0; i < this.coinCostList.length; i++) {
        this.options.xAxis[0].data.push(this.coinCostList[i].dayTime)
        this.options.series[0].data.push(this.coinCostList[i].sumVal)
      }
      this.coinChart.setOption(this.options)
    },
    onSubmit () {
      this.getCostCoin()
    },
    onSubmitUser () {
      this.getUserCount()
    },
    getUserCount () {
      const query = {
        rewardSource: 21,
        rewardType: 1
      }
      if (this.userQuery.date !== null && this.userQuery.date !== undefined && this.userQuery.date.length > 1) {
        query.from = this.userQuery.date[0]
        query.to = this.userQuery.date[1]
      }
      listStatLotteryCount(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.countList = result.data.list
        this.setUserChartData()
      })
    },
    setUserChartData () {
      this.userOptions.xAxis[0].data = []
      this.userOptions.series[0].data = []
      for (let i = 0; i < this.countList.length; i++) {
        this.userOptions.xAxis[0].data.push(this.countList[i].dayTime)
        this.userOptions.series[0].data.push(this.countList[i].playCount)
      }
      this.userChart.setOption(this.userOptions)
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>
.app-container {
}

.address-layout {
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #DCDFE6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409EFF;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -40px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #F2F6FC;
  font-weight: bold;
}

.layout-content {
  background: #ffffff;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #EBEEF5;
  padding: 10px;
}

.overview-layout {
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #DCDFE6;
}

.color-danger{
  color: #F56C6C;
}

.statistics-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}
.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}
.address-content{
  padding: 20px;
  font-size: 18px
}
</style>
