import { render, staticRenderFns } from "./StatLottery.vue?vue&type=template&id=decd45a0&scoped=true"
import script from "./StatLottery.vue?vue&type=script&lang=js"
export * from "./StatLottery.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "decd45a0",
  null
  
)

export default component.exports