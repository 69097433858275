<template>
  <div>
    <div class="overview-layout">
      <el-row :gutter="24">
        <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">今日中奖</div>
            <div class="layout-content" style="padding: 20px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">{{statHit.rmbTotal}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statHit.rmbCount}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statHit.coinTotal}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statHit.coinCount}}</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">人民币总额</el-col>
                <el-col :span="6" class="overview-item-title">人民币次数</el-col>
                <el-col :span="6" class="overview-item-title">金币总额</el-col>
                <el-col :span="6" class="overview-item-title">金币次数</el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">今日抽奖</div>
            <div class="layout-content" style="padding: 20px">
              <el-row>
                <el-col :span="8" class="color-danger overview-item-value">{{statPlay.playCost}}</el-col>
                <el-col :span="8" class="color-danger overview-item-value">{{statPlay.playerCount}}</el-col>
                <el-col :span="8" class="color-danger overview-item-value">{{statPlay.playTotal}}</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="8" class="overview-item-title">消耗金币数</el-col>
                <el-col :span="8" class="overview-item-title">抽奖人数</el-col>
                <el-col :span="8" class="overview-item-title">抽奖次数</el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="overview-layout" style="margin-top: 20px">
      <el-row :gutter="24">
        <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">人民币中奖</div>
            <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
              <el-form-item label="日期范围" prop="date">
                <el-date-picker
                  v-model="listQuery.date"
                  type="daterange"
                  align="right"
                  prop="date"
                  value-format="timestamp"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button @click="resetForm('listQuery')">重置</el-button>
              </el-form-item>
            </el-form>
            <div id="rmb-chart" style="width: 95%;height: 350px;"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">金币中奖与消耗</div>
            <el-form :model="coinQuery" ref="coinQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
              <el-form-item label="日期范围" prop="date">
                <el-date-picker
                  v-model="coinQuery.date"
                  type="daterange"
                  align="right"
                  prop="date"
                  value-format="timestamp"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="coinPickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmitCoin">查询</el-button>
                <el-button @click="resetForm('coinQuery')">重置</el-button>
              </el-form-item>
            </el-form>
            <div id="coin-chart" style="width: 95%;height: 350px;"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { GetStatTodayRequest, listStatLotteryHistory } from '../../../network/task'
import * as echarts from 'echarts'

export default {
  name: 'StatRed',
  data () {
    return {
      statHit: {
        rmbTotal: 0,
        rmbCount: 0,
        coinTotal: 0,
        coinCount: 0
      },
      statPlay: {
        playCost: 0,
        playerCount: 0,
        playTotal: 0
      },
      rmbChart: '',
      coinChart: '',
      rmbList: [],
      coinCostList: [],
      coinHitList: [],
      // 查询参数
      listQuery: {
        date: ''
      },
      coinQuery: {
        date: ''
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      coinPickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      options: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          show: false
        },
        series: [{
          name: '人民币(元)',
          type: 'line',
          smooth: true
        }]
      },
      coinOptions: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          data: ['中奖金币', '消耗金币']
        },
        series: [{
          name: '中奖金币',
          type: 'line',
          smooth: true
        }, {
          name: '消耗金币',
          type: 'line',
          smooth: true
        }]
      }
    }
  },
  mounted () {
    this.rmbChart = echarts.init(document.getElementById('rmb-chart'))
    this.coinChart = echarts.init(document.getElementById('coin-chart'))
    this.getStatToday()
    this.getRewardRmb()
    this.getRewardCoin()
  },
  methods: {
    getStatToday () {
      GetStatTodayRequest({ rewardSource: [8, 23] }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        this.statHit = result.data.statHit
        this.statPlay = result.data.statPlay
      })
    },
    onSubmit () {
      this.getRewardRmb()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getRewardRmb () {
      const query = {
        rewardSource: 8,
        rewardType: 3
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      listStatLotteryHistory(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.rmbList = result.data.list
        this.setRmbChartData()
      })
    },
    setRmbChartData () {
      this.options.xAxis[0].data = []
      this.options.series[0].data = []
      for (let i = 0; i < this.rmbList.length; i++) {
        this.options.xAxis[0].data.push(this.rmbList[i].dayTime)
        this.options.series[0].data.push(this.rmbList[i].sumVal)
      }
      this.rmbChart.setOption(this.options)
    },
    getRewardCoin () {
      const query = {
        rewardSource: 8,
        rewardType: 1
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      listStatLotteryHistory(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.coinHitList = result.data.list
        query.rewardSource = 23
        listStatLotteryHistory(query).then(res => {
          const result = res.data
          if (res.status !== 200) {
            return this.alertMessage('获取数据失败', 'error')
          }
          this.coinCostList = result.data.list
          this.setCoinChartData()
        })
      })
    },
    setCoinChartData () {
      this.coinOptions.xAxis[0].data = []
      this.coinOptions.series[0].data = []
      this.coinOptions.series[1].data = []
      for (let i = 0; i < this.coinHitList.length; i++) {
        this.coinOptions.xAxis[0].data.push(this.coinHitList[i].dayTime)
        this.coinOptions.series[0].data.push(this.coinHitList[i].sumVal)
        this.coinOptions.series[1].data.push(this.coinCostList[i].sumVal)
      }
      this.coinChart.setOption(this.coinOptions)
    },
    onSubmitCoin () {
      this.getRewardCoin()
    }
  }
}
</script>

<style scoped>
.app-container {
}

.address-layout {
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #DCDFE6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409EFF;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -40px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #F2F6FC;
  font-weight: bold;
}

.layout-content {
  background: #ffffff;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #EBEEF5;
  padding: 10px;
}

.overview-layout {
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #DCDFE6;
}

.color-danger{
  color: #F56C6C;
}

.statistics-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}
.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}
.address-content{
  padding: 20px;
  font-size: 18px
}
</style>
